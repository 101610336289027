import React from 'react';
import classnames from 'classnames';
// import { getImage } from 'gatsby-plugin-image';

import Item from './Item';

// import useStaticImage from 'hooks/graphql/useStaticImage';

import { list, noLine, rightGap } from './styles.module.scss';

const Program = ({ data, lastNoLine = false, setRightGap = false }) => {
  // const getStaticImage = useStaticImage();

  return (
    <ul
      className={classnames(
        list,
        lastNoLine ? noLine : '',
        setRightGap ? rightGap : ''
      )}
    >
      {data.length > 0 ? (
        data.map((item, idx) => {
          // const image = (photo) => getImage(getStaticImage(`ocx/${photo}`));

          return (
            <li key={item.title + idx}>
              <Item item={item} /*image={image}*/ />
            </li>
          );
        })
      ) : (
        <></>
      )}
    </ul>
  );
};

export default Program;
