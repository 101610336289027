// extracted by mini-css-extract-plugin
export var list = "styles-module--list--39Ml1";
export var noLine = "styles-module--noLine--1-xfQ";
export var listItem = "styles-module--listItem--3Wrjb";
export var rightGap = "styles-module--rightGap--3rMbf";
export var content = "styles-module--content--1_NOM";
export var img = "styles-module--img--2OdSI";
export var time = "styles-module--time--2c5mh";
export var marginLarge = "styles-module--marginLarge--3HQBS";
export var title = "styles-module--title--3B3jy";
export var subtitle = "styles-module--subtitle--2KvEn";
export var speaker = "styles-module--speaker--3AXIk";
export var speakerPhoto = "styles-module--speakerPhoto--IonGZ";
export var result = "styles-module--result--Kp9Dv";
export var showResult = "styles-module--showResult--1Mxhc";
export var noHover = "styles-module--noHover--19sla";
export var button = "styles-module--button--Oxr25";