export const BOXES_LIST = [
  {
    imageSmall: 'box-small-1',
    imageFull: '',
    title: 'VR',
    room: 'Room 1',
    description: '',
    url: '/events/screver-btq-program/vote/vr',
  },
  {
    imageSmall: 'box-small-2',
    imageFull: '',
    title: 'Gamification',
    room: 'Room 2',
    description: '',
    url: '/events/screver-btq-program/vote/gamification',
  },
  {
    imageSmall: 'box-small-3',
    imageFull: '',
    title: 'Omnichannel',
    room: 'Room 3',
    description: '',
    url: '/events/screver-btq-program/vote/omnichannel',
  },
];
