import React from 'react';
import classnames from 'classnames';
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// import useStaticImage from 'hooks/graphql/useStaticImage';

import {
  section,
  inner,
  time,
  description,
  title,
  image,
} from './styles.module.scss';

import { CARLY } from 'mocks/screver-btq-program/carly';

const CarlySec = () => {
  // const getStaticImage = useStaticImage();

  return (
    <section className={section}>
      <div className={inner}>
        <p className={classnames(time, 'font-aeroport')}>{CARLY.time}</p>
        <p className={classnames(description, 'font-epiloque')}>
          {CARLY.description}
        </p>
        <p className={classnames(title, 'font-epiloque')}>{CARLY.title}</p>
      </div>

      {/* <GatsbyImage
        className={image}
        image={getImage(getStaticImage(`ocx/carly.png`))}
        alt={'Carly Simone banner'}
      /> */}

      <img
        className={image}
        src={`/images/screver-btq/carly-btq-banner.png`}
        alt={'Carly Simone banner'}
      />
    </section>
  );
};

export default CarlySec;
