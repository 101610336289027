// extracted by mini-css-extract-plugin
export var list = "styles-module--list--pJnkA";
export var inner = "styles-module--inner--2cJBK";
export var listItem = "styles-module--listItem--Iocyx";
export var image = "styles-module--image--_28qs";
export var noise = "styles-module--noise--124MR";
export var title = "styles-module--title--Ac6V9";
export var room = "styles-module--room--3yxmC";
export var description = "styles-module--description--1Z_fD";
export var line = "styles-module--line--2uZDP";
export var button = "styles-module--button--1MIIE";