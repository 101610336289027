export const PROGRAM = {
  firstPart: [
    {
      startTime: '9:00',
      finishTime: '14:00',
      title: 'Co-working, workshops and one-on-ones with us',
      speaker: null,
      position: null,
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: null,
      slogan: null,
      description: null,
      id: 'Co-working',
    },
    {
      startTime: '14:00',
      finishTime: '15:00',
      title: 'Registration',
      speaker: null,
      position: null,
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: null,
      slogan: null,
      description: null,
      id: 'Registration',
    },
    {
      startTime: '15:00',
      finishTime: '15:15',
      title: 'Launch of the concept and grand opening',
      speaker: null,
      position: null,
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: null,
      slogan: null,
      description: null,
      id: 'Launch',
    },
    {
      startTime: '15:15',
      finishTime: '15:45',
      title: 'Keynote: Christian',
      speaker: null,
      position: null,
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: null,
      slogan: null,
      description: null,
      id: 'Keynote',
      button: {
        title: 'Topics to vote on →',
        url: '/events/screver-btq-program/vote/keynote',
      },
    },
    {
      startTime: '15:45',
      finishTime: '16:00',
      title: 'Break',
      speaker: null,
      position: null,
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: null,
      slogan: null,
      description: null,
      id: 'Break',
    },
    {
      startTime: '16:00',
      finishTime: '18:00',
      title: 'Hackathons',
      speaker: null,
      position: null,
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: null,
      slogan: null,
      description: null,
      id: 'Breakout-Rooms',
    },
  ],
};
