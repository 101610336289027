import React from 'react';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import Program from 'sections/ScreverBtqProgram/Program';
import BoxList from 'sections/ScreverBtqProgram/BoxList';
import CarlySec from 'sections/ScreverBtqProgram/CarlySec';
import FooterCommon from 'sections/ScreverOcxProgram/FooterCommon';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

import { PROGRAM } from 'mocks/screver-btq-program/program';

import 'sections/ScreverOcxProgram/common.scss';

const PAGES = [
  {
    title: 'Cinema',
    url: '/events/screver-btq-program/cinema',
  },
  {
    title: 'Hackathons',
    url: '/events/screver-btq-program/vote',
  },
  {
    title: '✌🏻feedback',
    url: '/events/screver-btq-program/feedback',
  },
];

const seo = {
  title: 'Screver Boutique Event: Let’s talk & Co-work | Program',
  metaDesc:
    "We're rolling out the red carpet for a unique three-day get-together in Zurich, exclusively for our special clients and close contacts. Let’s talk & Co-work",
  image:
    'https://wp.screver.com/wp-content/uploads/2023/08/screver-btq-meta-img.jpg',
  opengraphUrl: 'https://screver.com/events/screver-btq-program/program/',
};

const ProgramPage = () => {
  return (
    <>
      <OcxHelmet />

      <SEO seo={seo} themeColor={'#151517'} />

      <div className="scr-ocx-program scr-btq-event">
        <video
          playsInline
          autoPlay
          muted
          loop
          poster="https://wp.screver.com/wp-content/uploads/2023/05/bg-noise-2.png"
          src="https://wp.screver.com/wp-content/uploads/2023/05/Vawe_Capptoo_Noise.mp4"
          className="screver-btq-program-video"
        ></video>

        <HeaderCommon
          title="Program"
          url="/events/screver-btq-program/"
          styleProp="header-green-title"
        />

        <main className="">
          <div className="ocx-prog-wrap">
            <div className=""></div>
            <Program data={PROGRAM.firstPart} lastNoLine={true} />
            <BoxList />
            {/* <Program data={PROGRAM.secondPart} lastNoLine={true} /> */}
            <CarlySec />
          </div>
        </main>

        <FooterCommon linksToPages={PAGES} />
      </div>
    </>
  );
};

export default ProgramPage;
